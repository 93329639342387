import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;

  .offer-div {
    width: 50px;

    input {
      margin-left: 10px;
    }
  }

  > div {
    width: 100%;
    margin: 0 10px;

    > label {
      display: flex;
      padding: 9px 0;
      font-size: 14px;
      font-weight: bolder;
      color: #444444;
    }

    > input {
      border: 1px solid #dddddd;
      border-radius: 4px;
      height: 45px;
      padding: 0 15px;
      color: #444444;
      width: 100%;
      margin-bottom: 5px;

      &::placeholder {
        color: ${darken(0.25, '#dddddd')};
      }
    }

    > span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    .swatch {
      margin-top: 10px;
      padding: 5px;
      background: #fff;
      border-radius: 1px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
      display: inline-block;
      cursor: pointer;
    }

    .color {
      width: 36px;
      height: 14px;
      border-radius: 2px;
    }

    .popover {
      position: absolute;
      z-index: 2;
    }

    .cover {
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
  }
`;

/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import { IoMdCheckmark } from 'react-icons/io';
import { MdChevronLeft } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { loadSlides } from '~/store/modules/slides/actions';
import history from '~/services/history';

import {
  Container,
  Header,
  PageTitle,
  Back,
  Save,
  Info,
  SliderContent,
  ContentTitle,
  AddMoreDiv,
  AddMore,
} from './styles';

import Product from '~/components/Product';
import Image from '~/components/Image';

import api from '~/services/api';

export default function NewSlider() {
  const dispatch = useDispatch();
  const { slider_id } = useParams();

  const [slider, setSlider] = useState([]);
  const [sliderImage, setSliderImage] = useState([]);
  const [type, setType] = useState('image');
  const [count, setCount] = useState(0);
  const [productDiv, setProductDiv] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    async function handleLoadSlider() {
      if (slider_id) {
        const response = await api.get(`sliders/${slider_id}`);
        setSlider(response.data);
        setType(response.data.slider.type);
        setSliderImage(response.data.slider.slider_image);

        const products = [];
        let productCount = 0;
        if (response.data.content) {
          response.data.content.forEach(content => {
            products.push(
              <Product
                index={productCount}
                product={content.product}
                value={content.price}
                offer={content.offer}
                oldColor={content.color}
              />
            );
            productCount += 1;
          });

          setProductDiv(products);
          setCount(productCount - 1);
        } else {
          setProductDiv([<Product index={count} />]);
        }
      } else {
        setProductDiv([<Product index={count} />]);
      }
    }

    handleLoadSlider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleNewProduct() {
    setCount(count + 1);

    const divCount = count + 1;
    setProductDiv([...productDiv, <Product index={divCount} />]);
  }

  async function handleSubmit(data) {
    if (data.content) {
      data.content = data.content.map(pdc => {
        if (pdc.price !== '') {
          const formVal = pdc.price.split(' ');
          return { ...pdc, price: parseFloat(formVal[1], 10) };
        }
        return pdc;
      });
    }

    try {
      if (slider_id) {
        await api.put(`sliders/${slider_id}`, data);
        history.push('/slider');
        toast.success('Slide atualizado com sucesso!');
      } else {
        await api.post('sliders', data);
        history.push('/slider');
        toast.success('Slide adicionado com sucesso!');
      }
      dispatch(loadSlides());
    } catch (err) {
      toast.error('Erro ao adicionar/atualizar Slide!');
    }
  }

  return (
    <Container>
      <Form initialData={slider.slider} onSubmit={handleSubmit}>
        <Header>
          <PageTitle>Novo Slider</PageTitle>
          <div>
            <Back
              type="button"
              onClick={() => {
                history.push('/slider');
              }}
              style={{ marginRight: '10px' }}
            >
              <MdChevronLeft />
              <span>VOLTAR</span>
            </Back>
            <Save type="submit" onClick={() => {}}>
              <IoMdCheckmark />
              <span>SALVAR</span>
            </Save>
          </div>
        </Header>

        <ContentTitle>Informações</ContentTitle>
        <Info>
          <div>
            <Input
              name="name"
              label="Título"
              placeholder="Digite o título do Slide..."
            />
          </div>

          <div>
            <Input
              type="number"
              min="1"
              max="20"
              name="order"
              label="Ordem"
              placeholder="Escolha a ordem do Slide..."
            />
          </div>

          <div className="type-div">
            <Input
              type="radio"
              name="type"
              value={type}
              label="Imagem"
              onClick={() => setType('image')}
              checked={type === 'image'}
            />
          </div>
          <div className="type-div">
            <Input
              type="radio"
              name="type"
              value={type}
              label="Produtos"
              onClick={() => setType('content')}
              checked={type === 'content'}
            />
          </div>
        </Info>

        <SliderContent>
          <ContentTitle>Conteúdo</ContentTitle>
          {type === 'content' ? (
            productDiv
          ) : (
            <Image image={slider ? sliderImage : null} />
          )}
        </SliderContent>
      </Form>
      {type === 'content' ? (
        <AddMoreDiv>
          <AddMore
            type="submit"
            onClick={handleNewProduct}
            disabled={count >= 14}
          >
            <FaPlus />
            <span>NOVO PRODUTO ({`${count + 1}`}/15)</span>
          </AddMore>
        </AddMoreDiv>
      ) : null}
    </Container>
  );
}

import styled from 'styled-components';

export const ButtonOut = styled.button`
  background-color: transparent;
  border: none;

  color: transparent;
  &:hover {
    color: #333;
  }
`;

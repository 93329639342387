import React, { useEffect } from 'react';
import { FaPlayCircle, FaEdit } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

import { signOut } from '~/store/modules/auth/actions';
import { loadSlides } from '~/store/modules/slides/actions';
import history from '~/services/history';

import logo from '../../assets/logo.png';

import { Container, Content } from './styles';

export default function Home() {
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(signOut());
  }

  useEffect(() => {
    dispatch(loadSlides());
  }, [dispatch]);

  return (
    <Container>
      <Content>
        <img src={logo} alt="MaGu" />

        <button
          type="button"
          onClick={() => {
            history.push('/slider');
          }}
        >
          <div>
            <FaEdit size={30} />
            <div>Configurar Apresentação</div>
          </div>
        </button>

        <button
          type="button"
          onClick={() => {
            history.push('/apresentation');
          }}
        >
          <div>
            <FaPlayCircle size={30} />
            <div>Iniciar Apresentação</div>
          </div>
        </button>

        <a href="/" onClick={handleSignOut}>
          Sair do Sistema
        </a>
      </Content>
    </Container>
  );
}

/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import { FaPlus, FaPen, FaArchive } from 'react-icons/fa';
import { MdDeleteForever, MdChevronLeft } from 'react-icons/md';
import { toast } from 'react-toastify';

import { NavLink } from 'react-router-dom';
import history from '~/services/history';

import ProblemsModal from './Modal';

import {
  Container,
  Header,
  PageTitle,
  GoHome,
  NewContent,
  TableHeader,
  Table,
  TableItem,
  Actions,
  NoContent,
} from './styles';

import api from '~/services/api';

export default function Slider() {
  const [sliders, setSliders] = useState([]);

  async function loadSliders() {
    const response = await api.get('sliders');
    setSliders(response.data);
  }

  useEffect(() => {
    loadSliders();
  }, []);

  async function handleDelete(slider_id) {
    const confirm = window.confirm(
      'Tem certeza que deseja remover esse slider?'
    );

    if (confirm) {
      try {
        await api.delete(`sliders/${slider_id}`);
        loadSliders();
        toast.success('Slider deletado com sucesso!');
      } catch (err) {
        toast.error('Erro ao remover, tente novamente!');
      }
    }
  }

  return (
    <Container>
      <Header>
        <PageTitle>Todos os Sliders</PageTitle>
        <div>
          <GoHome
            type="button"
            onClick={() => {
              history.push('/home');
            }}
            style={{ marginRight: '10px' }}
          >
            <MdChevronLeft />
            <span>HOME</span>
          </GoHome>
          <NewContent
            type="button"
            onClick={() => {
              history.push('/slider/new');
            }}
          >
            <FaPlus />
            <span>NOVO SLIDER</span>
          </NewContent>
        </div>
      </Header>
      {sliders.length !== 0 ? (
        <Table>
          <TableHeader firstSmall>
            <th>Ordem</th>
            <th>Nome</th>
            <th>Ações</th>
          </TableHeader>
          {sliders.map(slider => (
            <TableItem>
              <td>#{slider.slider.order}</td>
              <td>{slider.slider.name}</td>
              <td>
                <Actions>
                  <ProblemsModal slide={slider} />
                  <NavLink to={`/slider/new/${slider.slider.id}`}>
                    <FaPen size={20} style={{ color: '#333' }} />
                  </NavLink>
                  <NavLink
                    to="#"
                    onClick={() => {
                      handleDelete(slider.slider.id);
                    }}
                  >
                    <MdDeleteForever size={25} style={{ color: '#333' }} />
                  </NavLink>
                </Actions>
              </td>
            </TableItem>
          ))}
        </Table>
      ) : (
        <NoContent>
          <div>
            <div>
              <FaArchive size={60} />
            </div>
            <span>Nenhum dado encontrado</span>
          </div>
        </NoContent>
      )}
    </Container>
  );
}

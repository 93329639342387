/* eslint-disable react/prop-types */
import React from 'react';
import { FaRegWindowClose } from 'react-icons/fa';

import Table from './Table';
import history from '~/services/history';

import { ButtonOut } from './styles';

const s = {
  container: 'abs fullW fullH',
  slideImage: 'fullH fullW imgCover',
};

export default function Slide({ position, transition, image, preview }) {
  return (
    <div
      className={`${s.container} ${position} ${transition}`}
      style={{ backgroundColor: '#fff' }}
    >
      <div
        style={{
          position: 'absolute',
          padding: '20px',
          backgroundColor: 'transparent',
        }}
      >
        {!preview && (
          <ButtonOut onClick={() => history.push('/home')}>
            <FaRegWindowClose size={40} />
          </ButtonOut>
        )}
      </div>
      {image.slider.type === 'image' ? (
        <img
          src={image.slider.slider_image.url}
          className={s.slideImage}
          alt="slide"
        />
      ) : (
        <Table slider={image.slider} content={image.content} />
      )}
    </div>
  );
}

/* eslint-disable react/prop-types */
import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import { IoMdEye } from 'react-icons/io';

import { NavLink } from 'react-router-dom';

import Slide from '../../Apresentation/SlideShow/Slide';

export default function ProblemsModal({ slide }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <NavLink to="#" onClick={handleOpen}>
        <IoMdEye size={25} style={{ color: '#333' }} />
      </NavLink>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          backgroundColor: '#fff',
          textAlign: 'center',
          justifyContent: 'center',
          maxHeight: '90%',
          maxWidth: '90%',
          margin: 'auto',
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            color: '#666666',
            borderRadius: '4px',
            maxWidth: '450px',
          }}
        >
          <Slide
            position="left0"
            transition="transition1l"
            image={slide}
            preview
          />
        </div>
      </Modal>
    </div>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 50px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #333;
`;

export const Logo = styled.img`
  max-width: 120px;
  max-height: 120px;
`;

export const Title = styled.h1`
  color: #333;
  font-size: 52px;
  padding-top: 20px;
  padding-left: 60px;
`;

export const Content = styled.div`
  flex: 1;
  margin: 10px 13% 10px 13%;
  height: 90%;
`;

export const EachContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px 0 80px;
  margin-bottom: 0.5%;
  background-color: #f5f5f5;
  border-radius: 8px;
  font-weight: bold;

  font-size: 4vh;
  height: ${props => (props.type ? props.type : '5%')};
`;

export const ProductName = styled.span`
  color: ${props => (props.color ? props.color : '#333')};
  width: 60%;
`;

export const ProductPrice = styled.span`
  color: ${props => (props.color ? props.color : '#333')};
  width: 30%;
`;

export const ProductOffer = styled.span`
  justify-content: center;
  color: #fff;
  width: 20%;
  border-radius: 4px;

  background-color: #a51b0b;
  text-align: center;
`;

/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Slide from './Slide';

const s = {
  container: 'fullW fullH rel overflowH',
  onScreen: 'left0',
  offScreenRight: 'left100vw',
  offScreenLeft: 'leftM100vw',
  transition: 'transition1l',
};

export default function SlideShow({ slides }) {
  const [slide1, setSlide1] = useState({
    id: 0,
    position: s.onScreen,
    transition: true,
  });

  const [slide2, setSlide2] = useState({
    id: 1,
    position: s.offScreenRight,
    transition: true,
  });

  const [, setCurrentId] = useState(0);

  function setSlideState(newSlide1, newSlide2, newCurrentId) {
    setSlide1(newSlide1);
    setSlide2(newSlide2);
    setCurrentId(newCurrentId);
  }

  function resetSlideTransitions(newslide1, newslide2, newcurrentId) {
    setTimeout(() => {
      newslide1.transition = true;
      newslide2.transition = true;

      setSlideState(newslide1, slide2, newcurrentId);
    }, 500);
  }

  function resetSlideOffScreen() {
    const newslide1 = slide1;
    const newslide2 = slide2;
    let newcurrentId;

    if (slide1.position === s.offScreenLeft) {
      newslide1.transition = false;
      newslide1.position = s.offScreenRight;
      newslide1.id = slide2.id + 1 === slides.length ? 0 : slide2.id + 1;
    } else {
      newslide2.transition = false;
      newslide2.position = s.offScreenRight;
      newslide2.id = slide1.id + 1 === slides.length ? 0 : slide1.id + 1;
    }

    setSlideState(newslide1, newslide2, newcurrentId);
    resetSlideTransitions(newslide1, newslide2, newcurrentId);
  }

  function transitionSlides() {
    const newslide1 = slide1;
    const newslide2 = slide2;
    let newcurrentId;

    if (slide1.position === s.onScreen) {
      newslide1.position = s.offScreenLeft;
      newslide2.position = s.onScreen;
      newcurrentId = slide2.id;
    } else {
      newslide1.position = s.onScreen;
      newslide2.position = s.offScreenLeft;
      newcurrentId = slide1.id;
    }

    setSlideState(newslide1, newslide2, newcurrentId);
    setTimeout(() => {
      resetSlideOffScreen();
    }, 1000);
  }

  // function startCarousel() {
  //   setInterval(() => {
  //     transitionSlides();
  //   }, 30000);
  // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setInterval(() => {
      transitionSlides();
    }, 20000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={s.container}>
      <Slide
        image={slides[slide1.id]}
        position={slide1.position}
        transition={slide1.transition ? s.transition : ''}
      />
      <Slide
        image={slides[slide2.id]}
        position={slide2.position}
        transition={slide2.transition ? s.transition : ''}
      />
    </div>
  );
}

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { signInRequest } from '~/store/modules/auth/actions';

import logo from '../../assets/logo.png';

const schema = Yup.object().shape({
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit({ password }) {
    dispatch(signInRequest(password));
  }

  return (
    <>
      <img src={logo} alt="MaGu" />

      <Form schema={schema} onSubmit={handleSubmit}>
        <Input
          label="SENHA DE ACESSO"
          name="password"
          type="password"
          placeholder="*************"
        />

        <button type="submit">
          {loading ? 'Carregando...' : 'Entrar no sistema'}
        </button>
      </Form>
    </>
  );
}

import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';

import { loadSlidesSuccess } from './actions';

export function* loadSlides() {
  const response = yield call(api.get, 'sliders');

  const slides = response.data;
  yield put(loadSlidesSuccess(slides));
}

export default all([takeLatest('@slides/LOAD_SLIDES', loadSlides)]);

/* eslint-disable react/prop-types */
import React from 'react';

import logo from '~/assets/logo.png';

import {
  Container,
  Header,
  Logo,
  Title,
  Content,
  EachContent,
  ProductName,
  ProductPrice,
  ProductOffer,
} from './styles';

export default function Table({ slider, content }) {
  const contentSize = 85 / content.length;

  return (
    <Container>
      <Header>
        <Logo src={logo} alt="MaGu" />
        <div>
          <Title>{slider.name}</Title>
        </div>
      </Header>

      <Content>
        {content.map(eachContent => (
          <>
            <EachContent color={eachContent.color} type={`${contentSize}%`}>
              <ProductName color={eachContent.color}>
                {eachContent.product}
              </ProductName>
              <ProductPrice color={eachContent.color}>
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(eachContent.price)}{' '}
                KG
              </ProductPrice>
              <ProductOffer color={eachContent.color}>
                {eachContent.offer ? 'OFERTA' : ''}
              </ProductOffer>
            </EachContent>
          </>
        ))}
      </Content>
    </Container>
  );
}

import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';

import Home from '../pages/Home';

import Slider from '../pages/Slider';
import NewSlider from '../pages/NewSlider';

import Apresentation from '../pages/Apresentation';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/home" component={Home} isPrivate />

      <Route path="/slider" exact component={Slider} isPrivate />
      <Route path="/slider/new/:slider_id" component={NewSlider} isPrivate />
      <Route path="/slider/new" component={NewSlider} isPrivate />

      <Route path="/apresentation" exact component={Apresentation} isPrivate />
    </Switch>
  );
}

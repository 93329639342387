import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  min-height: 100%;
  padding: 40px 140px;
  background-color: #f5f5f5;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PageTitle = styled.h1`
  color: #444444;
  margin-bottom: 40px;
`;

export const Back = styled.button`
  width: 160px;
  height: 36px;
  border-radius: 4px;
  background: #cccccc;
  color: #fff;
  justify-content: center;

  &:hover {
    background: ${darken(0.05, '#cccccc')};
  }

  span {
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: 50px;
  }
`;

export const Save = styled.button`
  width: 160px;
  height: 36px;
  border-radius: 4px;
  background: #a51b0b;
  color: #fff;
  justify-content: center;

  &:hover {
    background: ${darken(0.05, '#a51b0b')};
  }

  span {
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;

  .type-div {
    width: 60px;

    input {
      margin-left: 10px;
    }
  }

  > div {
    width: 100%;
    margin: 0 10px;

    > label {
      display: flex;
      padding: 9px 0;
      font-size: 14px;
      font-weight: bolder;
      color: #444444;
    }

    > input {
      border: 1px solid #dddddd;
      border-radius: 4px;
      height: 45px;
      padding: 0 15px;
      color: #444444;
      width: 100%;
      margin-bottom: 5px;

      &::placeholder {
        color: ${darken(0.25, '#dddddd')};
      }
    }

    > span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
  }
`;

export const SliderContent = styled.div``;

export const ContentTitle = styled.h2`
  color: #444444;
  margin-bottom: 10px;
`;

export const AddMoreDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const AddMore = styled.button`
  width: 260px;
  height: 36px;
  border-radius: 4px;
  background: #a51b0b;
  color: #fff;
  justify-content: center;

  &:hover {
    background: ${darken(0.05, '#a51b0b')};
  }

  &:disabled {
    opacity: 0.45;
    cursor: not-allowed;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
  }
`;

import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  height: 100%;
  background: #a51b0b;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 360px;
  max-height: 435px;
  text-align: center;
  background: #ffffff;
  border-radius: 4px;
  padding: 30px 30px 60px 30px;
  display: flex;
  flex-direction: column;

  img {
    max-width: 200px;
    align-self: center;
  }

  h1 {
    color: #a51b0b;
    font-weight: bold;
  }

  a {
    margin-top: 20px;
    color: #a51b0b;
    font-weight: bold;
  }

  button {
    margin: 30px 0 0;
    height: 45px;
    background: #a51b0b;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;

      div {
        margin-left: 10px;
        margin-top: 7px;
      }
    }

    &:hover {
      background: ${darken(0.05, '#a51b0b')};
    }
  }
`;

import React from 'react';
import { useSelector } from 'react-redux';

import Slideshow from './SlideShow';

import './custom.css';

const s = {
  container: 'screenW screenH dGray col',
  main: 'flex8 white',
};

export default function Apresentation() {
  const slides = useSelector(state => state.slides.allSlides);

  return (
    <div className={s.container}>
      <div className={s.main}>
        <Slideshow slides={slides} />
      </div>
    </div>
  );
}

export function loadSlides() {
  return {
    type: '@slides/LOAD_SLIDES',
  };
}

export function loadSlidesSuccess(slides) {
  return {
    type: '@slides/LOAD_SLIDES_SUCCESS',
    payload: { slides },
  };
}

import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  flex: 1;
  width: 100%;
  min-height: 100%;
  padding: 40px 140px;
  background-color: #f5f5f5;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PageTitle = styled.h1`
  color: #444444;
  margin-bottom: 40px;
`;

export const GoHome = styled.button`
  width: 160px;
  height: 36px;
  border-radius: 4px;
  background: #cccccc;
  color: #fff;
  justify-content: center;

  &:hover {
    background: ${darken(0.05, '#cccccc')};
  }

  span {
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: 50px;
  }
`;

export const NewContent = styled.button`
  width: 160px;
  height: 36px;
  border-radius: 4px;
  background: #a51b0b;
  color: #fff;
  justify-content: center;

  &:hover {
    background: ${darken(0.05, '#a51b0b')};
  }

  span {
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
  }
`;

export const NoContent = styled.div`
  display: flex;

  margin: 20px auto;
  max-width: 1200px;
  height: 350px;
  background: #ffffff;
  color: #999999;
  border-radius: 4px;

  div {
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`;

export const Table = styled.table`
  width: 100%;
  margin-top: 20px;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 20px;
`;

export const TableHeader = styled.tr`
  font-size: 16px;
  font-weight: bold;
  color: #444444;

  th {
    padding-left: 20px;
    word-wrap: break-word;
    padding-right: 40px;
  }

  ${props =>
    props.firstSmall &&
    css`
      th:first-child {
        width: 100px;
      }
    `}

  th:last-child {
    text-align: right;
  }
`;

export const TableItem = styled.tr`
  background: #fff;
  height: 60px;
  border: none;
  font-size: 16px;
  color: #666666;

  td {
    word-wrap: break-word;
    padding-left: 20px;
    border: none;

    button {
      border: 0;
      font-size: 28px;
    }
  }

  td:first-child {
    border-radius: 4px 0 0 4px;
  }

  td:last-child {
    text-align: right;
    padding-right: 20px;
    border-radius: 0 4px 4px 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  a {
    margin-left: 10px;
  }
`;

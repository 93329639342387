/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Input } from '@rocketseat/unform';
import CurrencyFormat from 'react-currency-format';

import { Container } from './styles';

export default function Product({ index, product, value, offer, oldColor }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorDisplay, setColorDisplay] = useState(oldColor || '#333');
  const [newOffer, setNewOffer] = useState(offer);
  const [newProduct, setNewProduct] = useState(product);
  const [newValue, setNewValue] = useState(value);

  function handleClick() {
    setDisplayColorPicker(!displayColorPicker);
  }

  function handleClose() {
    setDisplayColorPicker(false);
  }

  function handleChange(color) {
    setColorDisplay(color.hex);
  }

  return (
    <Container key={index}>
      <div>
        <Input
          name={`content[${index}][product]`}
          label="Produto"
          placeholder="Digite o nome do produto..."
          value={newProduct}
          onChange={e => setNewProduct(e.target.value)}
        />
        <Input
          type="hidden"
          name={`content[${index}][content_ordem]`}
          value={index}
        />
      </div>

      <div>
        <CurrencyFormat
          name={`content[${index}][price]`}
          label="Valor"
          placeholder="Digite o valor do produto..."
          value={newValue ? newValue : ""}
          onChange={e => setNewValue(e.target.value)}
          decimalScale={2}
          fixedDecimalScale
          customInput={Input}
          thousandSeparator={true}
          prefix={'R$ '}
        />
      </div>

      <div className="offer-div">
        <label onClick={() => handleClick()}>Cor</label>
        <div className="swatch" onClick={() => handleClick()}>
          <div
            className="color"
            style={{ backgroundColor: `${colorDisplay}` }}
          />
        </div>
        {displayColorPicker ? (
          <div className="popover">
            <div className="cover" onClick={() => handleClose()} />
            <SketchPicker
              color={colorDisplay}
              onChange={color => handleChange(color)}
            />
          </div>
        ) : null}
        <Input
          type="hidden"
          name={`content[${index}][color]`}
          value={colorDisplay}
        />
      </div>

      <div className="offer-div">
        <Input
          type="checkbox"
          name={`content[${index}][offer]`}
          label="Oferta"
          value={!!newOffer}
          onClick={() => {
            setNewOffer(!newOffer);
          }}
          checked={newOffer}
        />
      </div>
    </Container>
  );
}
